import styles from './Poll.module.css'
import clsx from 'clsx'
import { BasicStyledComponent, DSPoll } from 'shared-definitions/types'
import EditButton from 'shared-components/service/EditButton'
import { AppEventsContext } from '../contexts/AppEventsContext'

interface PollProps extends BasicStyledComponent {
  poll: DSPoll
  votedId: null | string
  onSubmit: (itemId: string) => void
  choicesClassName?: string
  showVoteResults: boolean
}

const Poll: React.VFC<PollProps> = ({
  showVoteResults,
  className,
  poll,
  onSubmit,
  votedId,
  choicesClassName,
}) => {
  const { appEvent } = AppEventsContext.useContainer()
  return (
    <>
      <EditButton pLink={poll.pLinkEdit} pLinkRequestUpdate={null} />
      <div
        className={clsx(className, {
          [styles.voted]: votedId !== null,
          [styles.showResults]: votedId === null || !showVoteResults,
        })}
      >
        <h3 className={styles.title}>{poll.question}</h3>
        <div className={styles.votes}>{`${poll.votes} votes`}</div>
        <div className={clsx(styles.choicesGrid, choicesClassName)}>
          {poll.items.map(item => {
            const percent = ((item.value / poll.votes) * 100).toFixed(0)
            return (
              <button
                type="button"
                key={item.id}
                className={clsx(styles.button, { [styles.buttonVoted]: votedId === item.id })}
                onClick={() => {
                  if (!votedId) {
                    onSubmit(item.id)
                  }
                  appEvent({
                    event: 'widget-interaction',
                    label: item.answer,
                    widgetType: 'Poll',
                    url: window.location.href,
                  })
                }}
              >
                <div className={styles.choiceText}>{item.answer}</div>
                <div className={styles.choicePercent}>
                  {percent}
                  {'%'}
                </div>
                <div className={styles.progressHolder}>
                  <div className={styles.progress} style={{ width: `${percent}%` }} />
                </div>
              </button>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Poll
