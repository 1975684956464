import clsx from 'clsx'
import { FC } from 'react'
import styleUtils from 'shared-components/styles/util.module.css'
import { DSNCWebSourcesSummary } from 'shared-definitions/types'
import styles from './NCWebSourcesSummary.module.css'
import { htmlReactParse } from 'shared-code/html'
import Linked from '../service/Linked'
import Globe from 'shared-svg/Globe.svg'

interface NCWebSourcesSummaryProps {
  data: DSNCWebSourcesSummary
}

const NCWebSourcesSummary: FC<NCWebSourcesSummaryProps> = ({ data }) => (
  <article className={clsx(styleUtils.nc, styles.container)} data-container-type="content">
    <h6 className={styles.title}>
      <Globe className={styles.titleIcon} />
      {data.title}
    </h6>
    <div className={styles.summary}>{htmlReactParse(data.summary)}</div>
    {data.sourcesTitle && <div className={styles.sourcesTitle}>{data.sourcesTitle}</div>}
    <div className={styles.sources}>
      {data.sources.map((source, index) => (
        <Linked key={index} {...source.pLink} className={styles.source}>
          {source.label}
        </Linked>
      ))}
    </div>
  </article>
)

export default NCWebSourcesSummary
