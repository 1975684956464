import { FC } from 'react'
import { msrpFormat } from 'shared-code/format'
import { DSNCDealsLargeAlt } from 'shared-definitions/types'
import Check from 'shared-svg/Check.svg'
import RWButton from '../buttons/RWButton'
import EditButton from '../service/EditButton'
import AppImage from '../ui/AppImage'
import Score from '../ui/Score'
import styles from './NCDealsLargeAlt.module.css'

interface NCDealsLargeAltProps {
  data: DSNCDealsLargeAlt
}

const NCDealsLargeAlt: FC<NCDealsLargeAltProps> = ({ data }) => {
  const [badge] = data.badges
  const [badgeImage] = badge && badge.images ? badge.images : []

  return (
    <>
      <EditButton pLink={data.pLinkEdit} pLinkRequestUpdate={data.pLinkRequestUpdate} elevated />
      <div {...data.dataAttrs} className={styles.container}>
        <div className={styles.imageBox}>
          <AppImage
            layout="fill"
            className={styles.imageContainer}
            imageClassName={styles.imageImage}
            image={data.image}
            sizes={[{ bp: 'lg', val: 'rem(560)' }, 'calc(100vw - rem(40))']}
          />
          {badgeImage && (
            <AppImage
              layout="raw"
              className={styles.badge}
              imageClassName={styles.badgeImage}
              image={badgeImage}
              sizes={60}
            />
          )}
        </div>
        <div className={styles.description}>
          <div className={styles.title}>{data.title}</div>
          <div className={styles.tags}>{data.tags.join(' • ')}</div>
          {data.score && <Score className={styles.score} score={data.score} />}
          {data.msrp && <div className={styles.msrp}>{msrpFormat(data.msrp)}</div>}
          <div className={styles.subtitle}>{data.subtitle}</div>
          <div className={styles.text}>{data.text}</div>
        </div>
        <div className={styles.sidebar}>
          <div className={styles.buttons}>
            {data.buttons.map((button, index) => (
              <RWButton
                variant={index === 0 ? 'secondary' : 'tertiary'}
                size="large"
                pLink={button.link.pLink}
                key={index}
              >
                {button.link.label}
              </RWButton>
            ))}
          </div>

          {data.features.positive && (
            <div className={styles.featuresPositive}>
              <div className={styles.featuresTitle}>{data.features.positive.title}</div>
              <ul className={styles.featuresList}>
                {data.features.positive.list.map((feature, index) => (
                  <li key={index} className={styles.feature}>
                    <Check className={styles.featureIcon} />

                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {data.features.negative && (
            <div className={styles.featuresNegative}>
              <div className={styles.featuresTitle}>{data.features.negative.title}</div>
              <ul className={styles.featuresList}>
                {data.features.negative.list.map((feature, index) => (
                  <li key={index} className={styles.feature}>
                    <svg
                      style={{ transform: 'scale(1.3)', transformOrigin: 'bottom' }}
                      className={styles.featureIcon}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 7 15"
                      fill="none"
                    >
                      <path d="M4.32941 10.6694L6.82954 8.16865C7.05682 7.9413 7.05682 7.56729 6.82954 7.33994C6.60225 7.1126 6.22833 7.1126 6.00105 7.33994L3.50092 9.84073L1.00079 7.33994C0.773501 7.1126 0.399581 7.1126 0.172296 7.33994C0.0586541 7.45361 0 7.60395 0 7.75429C0 7.90464 0.0586541 8.05498 0.172296 8.16865L2.67243 10.6694L0.172296 13.1702C0.0586541 13.2839 0 13.4342 0 13.5846C0 13.7349 0.0586541 13.8853 0.172296 13.9989C0.399581 14.2263 0.773501 14.2263 1.00079 13.9989L3.50092 11.4981L6.00105 13.9989C6.22833 14.2263 6.60225 14.2263 6.82954 13.9989C7.05682 13.7716 7.05682 13.3976 6.82954 13.1702L4.32941 10.6694Z" />
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default NCDealsLargeAlt
