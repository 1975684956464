import clsx from 'clsx'
import styles from './PostRowCounted.module.css'
import { BasicStyledComponent, DSPostShortWithAuthor } from 'shared-definitions/types'
import Linked from '../service/Linked'
import AppImage from '../ui/AppImage'
import CommentsCount from './CommentsCount'

interface PostRowCountedProps {
  post: DSPostShortWithAuthor
  count?: number
}

const PostRowCounted: React.FC<PostRowCountedProps & BasicStyledComponent> = ({
  className,
  count,
  post,
}) => (
  <Linked {...post.pLink} className={clsx(className, styles.container)}>
    {count !== undefined ? <div className={styles.count}>{count}</div> : null}
    <div className={styles.body}>
      <h5 className={styles.title}>{post.title}</h5>
      <div className={styles.bottomLine}>
        {post.author && <div className={styles.dotted}>{post.author?.name}</div>}
        <div className={styles.dotted}>{post.date}</div>
        {post.engagementContainerId && (
          <CommentsCount className={styles.dotted} containerId={post.engagementContainerId} />
        )}
      </div>
    </div>
    {post.image && <AppImage layout="raw" image={post.image} className={styles.image} sizes={70} />}
  </Linked>
)

export default PostRowCounted
