import clsx from 'clsx'
import { CSSProperties, FC, Fragment, useState } from 'react'
import styleUtils from 'shared-components/styles/util.module.css'
import {
  BasicStyledComponent,
  DSNCTrendingArticlesList,
  DSPostShortWithAuthor,
} from 'shared-definitions/types'
import { ViafouraAPIContext } from '../contexts/ViafouraAPIContext'
import { useAbortableEffect } from '../hooks/use-abortable-effect'
import PostRowCounted from '../posts/PostRowCounted'
import styles from './NCTrendingArticlesList.module.css'
import { useInView } from 'react-intersection-observer'
import { CommonDataContext } from '../contexts/CommonDataContext'
import RWButton from '../buttons/RWButton'

interface NCTrendingArticlesListProps {
  data: DSNCTrendingArticlesList
}

const NCTrendingArticlesList: FC<NCTrendingArticlesListProps & BasicStyledComponent> = ({
  data,
}) => {
  const {
    common: { strings },
  } = CommonDataContext.useContainer()
  const [isLoadedAllCards, setIsLoadedAllCards] = useState(false)
  const [ref, isVisible] = useInView({ triggerOnce: true })
  const [cards, setCards] = useState<DSPostShortWithAuthor[]>([])
  const { api } = ViafouraAPIContext.useContainer()

  const placeholderCount = data.pagination?.firstPageItems ?? data.limit
  const cardsShowCount = data.pagination?.firstPageItems ?? data.limit
  const cardsToShow = isLoadedAllCards ? cards : cards.slice(0, cardsShowCount)

  useAbortableEffect(
    async signal => {
      if (!isVisible) {
        return
      }

      const response = await api.getTrending(signal, {
        limit: data.limit + 1, // + 1 for the current post
        trendWindow: data.trendWindow,
        daysPublished: data.daysPublished,
      })

      setCards(
        response.trending
          .filter(
            item =>
              item.container_id !== data.containerId &&
              !data.ignoreContainerIds.includes(item.container_id)
          )
          .slice(0, data.limit)
          .map(item => ({
            sponsor: null,
            sponsored: false,
            author: null,
            engagementContainerId: item.container_id,
            pLink: { href: item.origin_url },
            title: item.origin_title,
            excerpt: item.origin_summary ?? null,
            image: item.origin_image_url
              ? {
                  srcSet: null,
                  caption: null,
                  credits: [],
                  size: null,
                  webpSrcSet: null,
                  title: item.origin_title,
                  src: item.origin_image_url,
                  alt: item.origin_image_alt,
                }
              : null,
            date: item.date_published ? new Date(item.date_published).toDateString() : '',
            score: null,
            badge: null,
            category: null,
          }))
      )
    },
    [isVisible, data.limit, data.trendWindow, data.daysPublished, data.containerId]
  )

  return (
    <div
      className={clsx(styles.container, styleUtils.nc)}
      ref={ref}
      style={{ '--placeholder-count': placeholderCount } as CSSProperties}
    >
      {data.title && <div className={styles.title}>{data.title}</div>}
      {cardsToShow.map((item, index) => (
        <Fragment key={index}>
          {index > 0 && <hr className={styles.rowSeparator} />}
          <PostRowCounted count={index + 1} post={item} />
        </Fragment>
      ))}
      {cardsShowCount < cards.length && !isLoadedAllCards ? (
        <RWButton
          variant="tertiary"
          size="medium"
          onClick={() => {
            setIsLoadedAllCards(true)
          }}
        >
          {strings.loadMore}
        </RWButton>
      ) : null}
    </div>
  )
}

export default NCTrendingArticlesList
